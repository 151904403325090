import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { PartnerService } from '@app/services/partner.service';
import { UserService } from '@app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-partner-status',
  templateUrl: './change-partner-status.component.html',
  styleUrls: ['./change-partner-status.component.css']
})
export class ChangePartnerStatusComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  updateStatusForm: UntypedFormGroup;
  pageHeading: string;
  primaryColor: string;
  accountantStatusOptions: Array<object>;
  userTypeOptions: Array<object>;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonUtility: CommonUtilityService,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,
    private partnerService: PartnerService,
    private fb: UntypedFormBuilder,
    private logger: NGXLogger
  ) {
    this.ngxService.startLoader('update-status-loader');
    this.pageHeading = 'Change External Accountant Status';
    this.updateStatusForm = this.fb.group({
      accountantStatus: ['', Validators.required],
      userType: ['', Validators.required]
    });
    this.accountantStatusOptions = [
      { id: 0, name: 'Not External Accountant', value: 0, class: 'dot-partner-status-not-accountant' },
      { id: 1, name: 'Verified External Accountant', value: 1, class: 'dot-partner-status-verified' },
      { id: 2, name: 'Pending Verification Request', value: 2, class: 'dot-partner-status-pending' },
      { id: 3, name: 'Rejected', value: 3, class: 'dot-partner-status-rejected' },
    ];
    this.userTypeOptions = [
      { id: 1, name: 'Customers - Default 2024',
        label: 'Customers - Default 2024', class: 'dot-customers---default-2024' },
      { id: 2, name: 'Customers - Default', label: 'Customers - Default Pre2024', class: 'dot-customers---default' },
      { id: 3, name: 'Customers - Early Adopters',
        label: 'Customers - Early Adopters', class: 'dot-customers---early-adopters' },
      { id: 4, name: 'Partners - Verified 2024', label: 'Partners - Verified 2024', class: 'dot-partners---verified-2024' },
      { id: 5, name: 'Partners - Verified', label: 'Partners - Verified Pre2024', class: 'dot-partners---verified' },
      { id: 6, name: 'Partners - Fan Club', label: 'Partners - Fan Club', class: 'dot-partners---fan-club' }
      // { id: 7, name: 'Partner - Reseller', label: 'Partner - Reseller', class: 'dot-partner---reseller' }
    ];
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail) {
      this.userService.checkUserIsExists(this.userEmail).then((userDetails: any) => {
        if (userDetails.externalAccountantStatus !== 1) {
          this.userTypeOptions = [
            { id: 1, name: 'Customers - Default 2024',
            label: 'Customers - Default 2024', class: 'dot-customers---default-2024' },
            { id: 2, name: 'Customers - Default', label: 'Customers - Default Pre2024', class: 'dot-customers---default' },
            { id: 3, name: 'Customers - Early Adopters',
              label: 'Customers - Early Adopters', class: 'dot-customers---early-adopters' }
            // { id: 7, name: 'Partner - Reseller', label: 'Partner - Reseller', class: 'dot-partner---reseller' }
          ];
        } else if (userDetails.externalAccountantStatus === 1) {
          this.userTypeOptions = [
            { id: 4, name: 'Partners - Verified 2024', label: 'Partners - Verified 2024', class: 'dot-partners---verified-2024' },
            { id: 5, name: 'Partners - Verified', label: 'Partners - Verified Pre2024', class: 'dot-partners---verified' },
            { id: 6, name: 'Partners - Fan Club', label: 'Partners - Fan Club', class: 'dot-partners---fan-club' },
          ];
        }
        this.updateStatusForm.patchValue({
          accountantStatus: userDetails.externalAccountantStatus,
          userType: userDetails.userType
        });
      }).catch(error => {
        this.commonUtility.displayErrorToast(this.pageHeading, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
    this.ngxService.stopLoader('update-status-loader');
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get updateStatusFC() {
    return this.updateStatusForm.controls;
  }

  changeStatus(): void {
    if (this.updateStatusFC.accountantStatus.value !== 1) {
      this.userTypeOptions = [
        { id: 1, name: 'Customers - Default 2024',
        label: 'Customers - Default 2024', class: 'dot-customers---default-2024' },
        { id: 2, name: 'Customers - Default', label: 'Customers - Default Pre2024', class: 'dot-customers---default' },
        { id: 3, name: 'Customers - Early Adopters',
          label: 'Customers - Early Adopters', class: 'dot-customers---early-adopters' }
        // { id: 7, name: 'Partner - Reseller', label: 'Partner - Reseller', class: 'dot-partner---reseller' }
      ];
    } else if (this.updateStatusFC.accountantStatus.value === 1) {
      this.userTypeOptions = [
        { id: 4, name: 'Partners - Verified 2024', label: 'Partners - Verified 2024', class: 'dot-partners---verified-2024' },
        { id: 5, name: 'Partners - Verified', label: 'Partners - Verified Pre2024', class: 'dot-partners---verified' },
        { id: 6, name: 'Partners - Fan Club', label: 'Partners - Fan Club', class: 'dot-partners---fan-club' },
      ];
    }
    this.updateStatusForm.patchValue({
      userType: (this.userTypeOptions[0] as any).name
    });
  }

  updateStatusSubmit(): void {
    this.ngxService.start('update-status-loader');
    if (this.updateStatusForm.valid) {
      const requestParameters = {
        userEmail: this.userEmail,
        accountantStatus: this.updateStatusFC.accountantStatus.value,
        userType: this.updateStatusFC.userType.value
      };
      console.log('DEBUG: -> ChangePartnerStatusComponent -> updateStatusSubmit -> requestParameters');
      this.partnerService.updatePartnerStatus(requestParameters).then((response: any) => {
        this.logger.info('INFO: -> ChangePartnerStatusComponent -> ' +
          'this.partnerService.updatePartnerStatus -> response', response);
        this.ngxService.stop('update-status-loader');
        if (requestParameters.accountantStatus === 1) {
        let messageHtml = '<div>';
        if (response.updatedInZohoSub) {
          messageHtml += '<i class="fa fa-check color-green-icon" aria-hidden="true">' +
            'Zoho Subscription Updated Successfully.</i><br>';
        } else {
          messageHtml += '<i class="fa fa-times-o color-red-icon" aria-hidden="true">' +
            'Zoho Subscription not updated.</i><br>';
        }
        if (response.sentVerifyEmail) {
          messageHtml += '<i class="fa fa-check color-green-icon" aria-hidden="true">' +
            'Email Notification sent successfully.</i> <br>';
        } else {
          messageHtml += '<i class="fa fa-times-o color-red-icon" aria-hidden="true">' +
            'Email Notification not send.</i> <br>';
        }
        if (response.playgroundCreated) {
          messageHtml += '<i class="fa fa-check color-green-icon" aria-hidden="true">' +
            '</i>Playground on-boarding process started successfully. <br>';
        } else {
          messageHtml += '<i class="fa fa-times-o color-red-icon" aria-hidden="true">' +
            '</i>' + response.playgroundMessage + ' <br>';
        }
        if (response.playgroundCreated) {
          messageHtml += '<i class="fa fa-check color-green-icon" aria-hidden="true">' +
            '</i>Partner-Edition Account created successfully.<br>';
        } else {
          messageHtml += '<i class="fa fa-times-o color-red-icon" aria-hidden="true">' +
            '</i>' + response.partnerEditionMessage + '<br>';
        }
        messageHtml += '</div>';

        this.commonUtility.displaySuccessToast(this.pageHeading, messageHtml);
        this.router.navigate(['/partners/all'], { queryParams: { search: this.userEmail }});
        } else {
          this.commonUtility.displaySuccessToast(this.pageHeading, 'Accountant Status updated Successfully.');
        }
      }).catch(error => {
        this.logger.warn('ERROR: -> ChangePartnerStatusComponent -> .then -> error', error);
        this.ngxService.stop('update-status-loader');
        this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.ngxService.stop('update-status-loader');
      this.commonUtility.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }
}
