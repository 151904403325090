<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 zoho-sub-webhook-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <span class="float-end">
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i
                                class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 zoho-sub-webhook-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 zoho-sub-webhook-filter-group">
                            <label for="searchinput"
                                matToolTip="You can search by Event Type, Subscription Id, Customer Id, Customer Name">Global
                                Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 zoho-sub-webhook-filter-group">
                            <label matTooltip="You can select multiple Status">Status</label>
                            <mat-select [(ngModel)]="selectedStatus" class="form-control" multiple
                                placeholder="Select Status">
                                <mat-option #allSelectedStatus (click)="toggleAllTypeStatus()" [value]="0">All
                                </mat-option>
                                <mat-option *ngFor="let item of statusList" [value]="item.value"
                                    (click)="statusTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span>
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-3">

                        <label matTooltip="You can select multiple Event type">Event type</label>
                        <mat-select [(ngModel)]="selectedEventTypes" class="form-control" multiple
                            placeholder="Select Event type">
                            <mat-option #allSelectedEventType (click)="toggleAllEventType()" [value]="0">All
                            </mat-option>
                            <mat-option *ngFor="let item of eventTypeList" [value]="item.value"
                                (click)="eventTypeTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span>
                            </mat-option>
                        </mat-select>

                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <label matTooltip="Columns hide/show">Columns</label>
                        <mat-select [compareWith]="compareFn" class="form-control"
                            panelClass="mat-select-autowidth-panel-class" (selectionChange)="columnChange()"
                            [(ngModel)]="selectedColumns" multiple>
                            <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div class="mb-3 zoho-sub-webhook-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>&nbsp; events
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} events
                        </p>
                        <pagination-controls id="all-webhook-events" (pageChange)="getAllWebhookEvents($event)"
                            responsive="true" previousLabel="Previous" nextLabel="Next"
                            maxSize="3"></pagination-controls>
                    </div>
                </div>
            </div>


            <div class="row filtered-by-box">
                <div class="col-md-12 mb-2" *ngIf="!selectedStatus.includes(0) || searchString">
                    <span class="filter-by-text">Filtered by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedStatus.includes(0)">
                        <strong>Status:</strong> ({{selectedStatus.join(', ')}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close"
                            (click)="resetStatusSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show type-filter ms-2" *ngIf="searchString">
                        <strong>Global Search:</strong> ({{searchString}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                </div>
                <div class="col-md-12">
                    <span class="filter-by-text">Sort by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show payment-status-filter ms-2">
                        <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending':
                        'Descending'}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row zoho-sub-webhook-result-container white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title table-responsive">
                <table aria-describedby="webhook events table"
                    class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                    id="webhook-events-table">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let column of columns; let i = index">
                                <th *ngIf="checkColumnVisibility(column) && !column.hide"
                                    class="sort-icon table-sorting"
                                    [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort === -1}"
                                    (click)="sortBy(column.label)">{{column.label}}</th>
                                <th *ngIf="checkColumnVisibility(column) && column.hide">{{column.label}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let webhookEvent of webhookEvents | paginate: { id: 'all-webhook-events', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                            <td *ngIf="checkColumnVisibility(columns[0])">
                                <span *ngIf="webhookEvent.eventDate">{{webhookEvent.eventDate}}</span>
                                <span *ngIf="!webhookEvent.eventDate" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[1])">
                                <span *ngIf="webhookEvent.eventId">{{webhookEvent.eventId}}</span>
                                <span *ngIf="!webhookEvent.eventId" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[2])">
                                <span *ngIf="webhookEvent.eventType">{{webhookEvent.eventType}}</span>
                                <span *ngIf="!webhookEvent.eventType" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[3])">
                                <span *ngIf="webhookEvent.status"><span
                                        class="badge badge-{{webhookEvent.statusClass}}-process-status">{{webhookEvent.status}}</span></span>
                                <span *ngIf="!webhookEvent.status" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[4])">
                                <span>{{webhookEvent.tries || 0}}</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[5])">
                                <span *ngIf="webhookEvent.lastUpdatedDate">{{webhookEvent.lastUpdatedDate}}</span>
                                <span *ngIf="!webhookEvent.lastUpdatedDate" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[6])">
                                <span *ngIf="webhookEvent?.payload"><a class="btn btn-primary btn-sm ms-2"
                                        (click)="openPayloadDialog(webhookEvent.payload)">Payload</a></span>
                                <span *ngIf="webhookEvent?.showHistory"><a class="btn btn-primary btn-sm ms-2"
                                        (click)="openHistoryDialog(webhookEvent)">Show history</a></span>
                                <span><a class="btn btn-primary btn-sm ms-2"
                                        (click)="retry(webhookEvent?.eventId, webhookEvent?.status)">Retry</a></span>
                            </td>
                        </tr>
                        <tr *ngIf="webhookEvents && webhookEvents.length === 0">
                            <td colspan="9" class="not-available text-center">No event available at this time</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
