<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 reseller-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div class="col-md-6 p-0 display-flex">
                    <h5>{{pageHeading}}</h5>
                </div>
                <div class="col-md-6 p-0">
                    <span class="float-end">

                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row white-bg border-line">
        <div class="ibox col-md-12 reseller-filters-container">
            <div class="ibox-title">
                <form [formGroup]="resellerFormGroup" (ngSubmit)="saveReseller()">
                    <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper">
                        <mat-step state="account_box">
                            <ng-template matStepLabel>Reseller Details</ng-template>
                            <div class="row">
                                <div class="mb-3 col-md-4">
                                    <label>Company Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" placeholder="ex. CustomBooks"
                                        formControlName="name" />
                                    <div *ngIf="resellerForm.name.invalid && (resellerForm.name.dirty || resellerForm.name.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="resellerForm.name.errors.required">Company Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 col-md-4">
                                    <label>Widget Id <span class="required">*</span></label>
                                    <input type="text" class="form-control" placeholder="ex. CBK-Widget"
                                        formControlName="widgetId" appResellerWidgetIdUniquenessCheck />
                                    <div *ngIf="resellerForm.widgetId.invalid && (resellerForm.widgetId.dirty || resellerForm.widgetId.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="resellerForm.widgetId.errors.required">Widget Id
                                            is required.
                                        </div>
                                        <div *ngIf="resellerForm.widgetId.errors?.serverError">
                                            {{resellerForm.widgetId.errors?.serverError}}
                                        </div>
                                        <div *ngIf="resellerForm.widgetId.errors?.widgetIdValidator">
                                            {{resellerForm.widgetId.errors?.widgetIdValidator}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 col-md-12">
                                    <a class="btn btn-default me-2" [routerLink]="['/partner/reseller/all']" routerLinkActive="router-link-active">Cancel</a>
                                    <button type="button" class="btn btn-primary float-end" matStepperNext [disabled]="resellerForm.widgetId.invalid || resellerForm.name.invalid">Next</button>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step state="settings">
                            <ng-template matStepLabel>Marketing Details</ng-template>
                            <div class="row">
                                <div class="mb-3 col-md-4">
                                    <mat-checkbox formControlName="showInAsc">Create CBK Marketing Page
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="row" *ngIf="!resellerForm.showInAsc.value">
                                <div class="mb-3 col-md-4">
                                    <label>Reseller Marketing URL/Website</label>
                                    <div class="input-group">
                                    <input type="text" class="form-control"
                                        placeholder="ex. https://www.custombooks.com"
                                        formControlName="marketingUrl" />
                                    <div class="input-group-append">
                                        <a class="btn btn-primary" target="_blank" href="{{resellerForm.marketingUrl.value}}">Open</a>
                                    </div>
                                    </div>

                                    <div *ngIf="resellerForm.marketingUrl.invalid && (resellerForm.marketingUrl.dirty || resellerForm.marketingUrl.touched)"
                                        class="invalid-feedback">
                                        <div *ngIf="resellerForm.marketingUrl.errors.pattern">Please enter a valid URL that starts with https.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="resellerForm.showInAsc.value">
                                <h5>CBK Marketing Page Details</h5>
                                <div class="row">
                                    <div class="mb-3 col-md-4">
                                        <label>URL Identifier <span class="required">*</span></label>
                                        <input type="text" class="form-control" placeholder="ex. gusto"
                                            formControlName="resellerId" appResellerUrlUniquenessCheck />
                                        <div *ngIf="resellerForm.resellerId.invalid && (resellerForm.resellerId.dirty || resellerForm.resellerId.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="resellerForm.resellerId.errors.pattern">Please enter a valid url
                                                state. you can only use [a-z] [A-Z] [0-9] characters.
                                            </div>
                                            <div *ngIf="resellerForm.resellerId.errors?.serverError">
                                                {{resellerForm.resellerId.errors?.serverError}}
                                            </div>
                                            <div *ngIf="resellerForm.resellerId.errors?.urlValidator">
                                                {{resellerForm.resellerId.errors?.urlValidator}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="mb-3 col-md-4">
                                        <label>Marketing URL</label>
                                        <div class="input-group">
                                        <input type="text" class="form-control"
                                            placeholder="ex. https://www.custombooks.com/partners/gusto"
                                            formControlName="marketingUrl" readonly />
                                        <div class="input-group-append" *ngIf="pageHeading === 'Edit Reseller'">
                                            <a class="btn btn-primary" target="_blank" href="{{resellerForm.marketingUrl.value}}">Open</a>
                                        </div>
                                        </div>
                                        <div *ngIf="resellerForm.marketingUrl.invalid && (resellerForm.marketingUrl.dirty || resellerForm.marketingUrl.touched)"
                                            class="invalid-feedback">
                                            <div *ngIf="resellerForm.marketingUrl.errors.pattern">Please enter a valid
                                                url.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" formGroupName="templateConfig">
                                    <div class="col-md-6" formGroupName="offers">
                                        <h6>Title Bar</h6>
                                        <div class="mb-3">
                                            <mat-checkbox formControlName="visible">Visible</mat-checkbox>
                                        </div>
                                        <div class="mb-3"
                                            *ngIf="resellerForm.templateConfig.value.offers.visible">
                                            <label>Title Bar Content <span class="required">*</span></label>
                                            <input type="text" class="form-control" formControlName="content" />
                                        </div>
                                    </div>
                                    <div class="col-md-6" formGroupName="header">
                                        <h6>Content Header</h6>
                                        <div class="mb-3">
                                            <mat-checkbox formControlName="visible">Visible</mat-checkbox>
                                        </div>
                                        <div class="mb-3"
                                            *ngIf="resellerForm.templateConfig.value.header.visible">
                                            <label>Header Text <span class="required">*</span></label>
                                            <input type="text" class="form-control" formControlName="content" />
                                            <div *ngIf="resellerFormGroup.get('templateConfig.header.content').invalid && (resellerFormGroup.get('templateConfig.header.content').dirty || resellerFormGroup.get('templateConfig.header.content').touched)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="resellerFormGroup.get('templateConfig.header.content').errors.maxlength">
                                                    Header text length max 140 characters.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" formGroupName="templateConfig">
                                    <div class="col-md-6" formGroupName="logo">
                                        <h6>Logo Details</h6>
                                        <div class="mb-3">
                                            <mat-checkbox formControlName="visible">Visible</mat-checkbox>
                                        </div>
                                        <div class="mb-3" *ngIf="resellerForm.templateConfig.value.logo.visible">
                                            <label class="app-image-wrapper mt-2">
                                                Update Logo
                                                <input type="file" matTooltip="Update Logo" accept=".jpg, .jpeg, .png"
                                                    hidden="" (change)="onUpdateLogo($event)">
                                            </label>
                                            <br>
                                            <label>Logo URL <span class="required">*</span></label>
                                            <div class="input-group">
                                            <input type="text" class="form-control" formControlName="url" />
                                            <div class="input-group-append">
                                                <a class="btn btn-primary" target="_blank" href="{{resellerForm.templateConfig.value.logo.url}}">Open</a>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="mb-3" *ngIf="resellerForm.templateConfig.value.logo.visible">
                                            <label>Company URL <span class="required">*</span></label>
                                            <div class="input-group">
                                            <input type="text" class="form-control" formControlName="link" />
                                            <div class="input-group-append">
                                                <a class="btn btn-primary" target="_blank" href="{{resellerForm.templateConfig.value.logo.link}}">Open</a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" formGroupName="paragraph">
                                        <h6>Paragraph Details</h6>
                                        <div class="mb-3">
                                            <mat-checkbox formControlName="visible">Visible</mat-checkbox>
                                        </div>
                                        <div class="mb-3"
                                            *ngIf="resellerForm.templateConfig.value.paragraph.visible">
                                            <label>Content <span class="required">*</span></label>
                                            <textarea class="form-control" formControlName="content"
                                                rows="4"></textarea>
                                        </div>
                                    </div>
                                </div>
                               <div class="row" formGroupName="templateConfig">
                                   <h6>Tracking script</h6>
                                   <div class="col-md-4" formGroupName="googleAnalyticsScript">
                                     <div class="mb-3">
                                       <mat-checkbox formControlName="enabled">Google analytics script</mat-checkbox>
                                     </div>
                                     <div class="mb-3" *ngIf="resellerForm.templateConfig.value.googleAnalyticsScript.enabled">
                                       <label>Measurement Id <span class="required">*</span> </label>
                                       <input class="form-control" formControlName="measurementId" type="text">
                                   </div>
                                   </div>
                                   <div class="col-md-4" formGroupName="facebookPixelScript">
                                     <div class="mb-3">
                                       <mat-checkbox formControlName="enabled">Facebook pixel script</mat-checkbox>
                                     </div>
                                     <div class="mb-3" *ngIf="resellerForm.templateConfig.value.facebookPixelScript.enabled">
                                       <label>Pixel Id <span class="required">*</span> </label>
                                       <input class="form-control" formControlName="pixelId" type="text">
                                     </div>
                                   </div>
                                  <div class="col-md-4" formGroupName="hotjarScript">
                                   <div class="mb-3">
                                     <mat-checkbox formControlName="enabled">Hotjar script</mat-checkbox>
                                   </div>
                                   <div class="mb-3" *ngIf="resellerForm.templateConfig.value.hotjarScript.enabled">
                                     <label>Site Id <span class="required">*</span> </label>
                                     <input class="form-control" formControlName="siteId" type="text">
                                     <label>Version <span class="required">*</span> </label>
                                     <input class="form-control" formControlName="version" type="text">
                                   </div>
                                 </div>
                                 </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 col-md-12">
                                    <a class="btn btn-default me-2" [routerLink]="['/partner/reseller/all']" routerLinkActive="router-link-active">Cancel</a>
                                    <button type="button" class="btn btn-primary" matStepperPrevious>Back</button>
                                    <button type="button" class="btn btn-primary float-end" matStepperNext [disabled]="!resellerFormGroup.valid">Next</button>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step state="note">
                            <ng-template matStepLabel>Signed Contract</ng-template>
                            <div class="row">
                                <div class="mb-3 col-md-6">
                                    <label class="app-image-wrapper mt-2">
                                        Upload Signed Contract
                                        <input type="file" matTooltip=" Upload Signed Contract" accept=".jpg, .jpeg, .png"
                                            hidden="" (change)="onUpdateSignedDoc($event)">
                                    </label>
                                    <br>
                                    <label>Signed Contract Link</label>
                                    <div class="input-group">
                                    <input type="text" class="form-control" formControlName="singedDocumentURL" />
                                    <div class="input-group-append">
                                        <a class="btn btn-primary" target="_blank" href="{{resellerForm.singedDocumentURL.value}}">Open</a>
                                    </div>
                                    </div>
                                </div>
                                <div class="mb-3 col-md-12">
                                    <a class="btn btn-default me-2" [routerLink]="['/partner/reseller/all']" routerLinkActive="router-link-active">Cancel</a>
                                    <button type="button" class="btn btn-primary" matStepperPrevious>Back</button>
                                    <button type="submit" class="btn btn-primary float-end" matStepperNext [disabled]="!resellerFormGroup.valid">Submit</button>
                                </div>
                            </div>
                        </mat-step>
                    </mat-horizontal-stepper>
                </form>
            </div>
        </div>
    </div>
</div>
