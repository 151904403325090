import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ExportService } from '@app/services/export.service';
import { NGXLogger } from 'ngx-logger';
import * as dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
import { BackButtonService } from '@app/services/back-button.service';
import { MatSelectChange } from '@angular/material/select';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-all-accounts',
  templateUrl: './all-accounts.component.html',
  styleUrls: ['./all-accounts.component.css']
})
export class AllAccountsComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;
  viewType: string;

  searchString: string;
  selectedPlans: any;
  selectedInterval: any;
  selectedStatus: any;
  selectedPaymentStatus: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  accounts: [];
  fromShowing: any;
  toShowing: any;

  paymentStatusList = [
    {id: 1, label: 'Paid', value: 'Paid', class: 'dot dot-paid-payment-status'},
    {id: 2, label: 'Past Due', value: 'Past Due', class: 'dot dot-past-due-payment-status'},
    {id: 10, label: 'Paused', value: 'Paused', class: 'dot dot-paused-payment-status'},
    {id: 3, label: 'Suspended', value: 'Suspended', class: 'dot dot-suspended-payment-status'},
    {id: 4, label: 'Unbilled', value: 'Unbilled', class: 'dot dot-unbilled-payment-status'},
    {id: 5, label: 'Processing', value: 'Processing', class: 'dot dot-processing-payment-status'},
    {id: 6, label: 'Cancelled', value: 'Cancelled', class: 'dot dot-cancelled-payment-status'},
    {id: 7, label: 'Partner', value: 'Partner', class: 'dot dot-partner-payment-status'},
    {id: 8, label: 'Trial Expires', value: 'Trial Expires', class: 'dot dot-trial-expires-payment-status'},
    {id: 9, label: 'Trial Expired', value: 'Trial Expired', class: 'dot dot-trial-expired-payment-status'}
  ];
  plansList = [
    {id: 1, label: 'Entrepreneur', value: 'Entrepreneur', class: 'dot dot-entrepreneur-plan'},
    {id: 2, label: 'Professional', value: 'Professional', class: 'dot dot-professional-plan'},
    {
      id: 3,
      label: 'Professional w/eCommerce',
      value: 'Professional w/eCommerce',
      class: 'dot dot-professional-w-ecommerce-plan'
    },
    {
      id: 4,
      label: 'Bank Accounting Online',
      value: 'Bank Accounting Online',
      class: 'dot dot-bank-accounting-online-plan'
    },
    {id: 5, label: 'Partner Edition', value: 'Partner Edition', class: 'dot dot-partner-edition-plan'},
    {id: 6, label: 'Start up', value: 'Start up', class: 'dot dot-start-up-plan'},
    {id: 7, label: 'WriteUp', value: 'WriteUp', class: 'dot dot-writeup-plan'},
    {id: 8, label: 'Business', value: 'Business', class: 'dot dot-business-plan'},
    {id: 9, label: 'Not Available', value: 'Not Available', class: ''},
  ];
  statusList = [
    {id: 2, label: 'Live', value: 'Live', key: 'live', class: 'dot dot-live-status'},
    {id: 3, label: 'Test', value: 'Test', key: 'test', class: 'dot dot-test-status'},
    {id: 4, label: 'Sandbox', value: 'Sandbox', key: 'sandbox', class: 'dot dot-sandbox-status'},
    {id: 5, label: 'Delete', value: 'Delete', key: 'delete', class: 'dot dot-delete-status'},
    {id: 6, label: 'Not Available', value: 'Not Available', key: null, class: ''}
  ];
  columns = [
    {id: 1, key: 'accountNumber', label: 'Account No', sort: 1, hide: 0},
    {id: 2, key: 'subscriptionOwnerEmail', label: 'Subscription Owner', sort: 0, hide: 0},
    {id: 3, key: 'companyName', label: 'Company Name', sort: 0, hide: 0},
    {id: 4, key: 'status', label: 'Status', sort: 0, hide: 0},
    {id: 5, key: 'planName', label: 'Plan Name', sort: 0, hide: 0},
    {id: 6, key: 'interval', label: 'Interval', sort: 0, hide: 0},
    {id: 7, key: 'franchisee', key1: 'name', label: 'Franchisee', sort: 0, hide: 0},
    {id: 8, key: 'closingDate', label: 'Closing Date', sort: 0, hide: 0},
    {id: 9, key: 'paymentStatus', key1: 'status', label: 'Payment Status', sort: 0, hide: 0},
    {id: 10, key: 'createdDate', label: 'Account Creation', sort: 0, hide: 0},
    {id: 11, key: 'resellerId', label: 'Reseller Id', sort: 0, hide: 0}
  ];
  interval = [
    {id: 1, value: 'months', label: 'Monthly'},
    {id: 2, value: 'years', label: 'Annual'}
  ];
  companyName: string;
  ranges = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
    'Last Week': [dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')],
    'Next Week': [dayjs().startOf('day'), dayjs().add(7, 'days').endOf('day')],
    'Last 30 Days': [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  dateRange = {
    start: dayjs().subtract(7, 'days').startOf('day'),
    end: dayjs().endOf('day'),
  };
  selectedDateRange = {
    start: dayjs().subtract(7, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  trialExpirationFilter: boolean;
  currentUrl: string;
  cookieKey: string;
  @ViewChild('allSelectedStatus') private allSelectedStatus: MatOption;
  @ViewChild('allSelectedPlan') private allSelectedPlan: MatOption;
  @ViewChild('allSelectedInterval') private allSelectedInterval: MatOption;
  @ViewChild('allSelectedPaymentStatus') private allSelectedPaymentStatus: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private accountService: AccountService,
    private commonUtility: CommonUtilityService,
    private exportService: ExportService,
    private logger: NGXLogger,
    private location: Location,
    private ngxService: NgxUiLoaderService,
    private backButtonService: BackButtonService
  ) {
    this.currentUrl = router.url;
    this.pageHeading = 'All Accounts';
    this.cookieKey = 'cf_all_accounts';
    this.viewType = 'LIST'; // default selected view type
    this.trialExpirationFilter = false;

    // persist filters while click on back/refresh
    const filters: any = this.backButtonService.getFilters(this.cookieKey);
    if (filters) {
      console.log('DEBUG: -> AllAccountsComponent -> filters', filters);
      this.setAppliedFilters(filters);
    } else {
      this.setDefaultFilters();
    }

    // search query parameter passed >> paymentStatus, expirationDate
    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((params: any) => {
        const selectedType = params?.type;
        const expirationFilter = params?.filter;
        if (selectedType) {
          if (this.paymentStatusList.map(item => item.value).includes(selectedType)) {
            this.setDefaultFilters();
            this.selectedPaymentStatus = [selectedType];
            this.selectedStatus = ['Live', 'Not Available'];
            this.location.replaceState('/accounts/all');
          } else {
            this.commonUtility.displayErrorToast('All Accounts', 'Wrong Payment Type value passed in URL parameters.');
          }
        }
        if (expirationFilter) {
          if (expirationFilter === 'Next') {
            this.trialExpirationFilter = true;
            this.dateRange = {
              start: dayjs().startOf('day'),
              end: dayjs().add(7, 'days').endOf('day'),
            };
            this.selectedDateRange = {
              start: dayjs().startOf('day').format('MM-DD-YYYY'),
              end: dayjs().add(7, 'days').endOf('day').format('MM-DD-YYYY'),
            };
          } else if (expirationFilter === 'Last') {
            this.trialExpirationFilter = true;
            this.dateRange = {
              start: dayjs().subtract(7, 'days').startOf('day'),
              end: dayjs().endOf('day'),
            };
            this.selectedDateRange = {
              start: dayjs().subtract(7, 'days').startOf('day').format('MM-DD-YYYY'),
              end: dayjs().endOf('day').format('MM-DD-YYYY'),
            };
          } else {
            this.commonUtility.displayErrorToast('All Accounts', 'Wrong Payment Type value passed in URL parameters.');
          }
        }
      });

    // search query parameter passed
    this.route.queryParams
      .pipe(
        filter((params) => params?.search),
        takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        this.setDefaultFilters();
        this.searchString = params.search;
        this.getAllAccounts(1);
        this.location.replaceState('/accounts/all');
      }
    );
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `All Accounts | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllAccounts(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  changeViewType(viewType) {
    this.viewType = viewType;
  }

  // #region  filters-properties
  clearSearch() {
    this.searchString = '';
    this.getAllAccounts(1);
  }

  onChangeSearchText() {
    this.getAllAccounts(1);
  }

  changePaymentStatusFilter() {
    if (this.currentUrl.includes('Next') || this.currentUrl.includes('Last')) {
      this.trialExpirationFilter = this.containsOnly(['Trial Expires', 'Trial Expired'], this.selectedPaymentStatus);
    }
    this.getAllAccounts(1);
  }

  toggleAllTypePaymentStatus() {
    if (this.allSelectedPaymentStatus.selected) {
      this.selectedPaymentStatus = [...this.paymentStatusList.map(item => item.value), 0];
    } else {
      this.selectedPaymentStatus = [];
    }
    this.changePaymentStatusFilter();
  }

  paymentStatusTouchPerOne() {
    if (this.allSelectedPaymentStatus.selected) {
      this.allSelectedPaymentStatus.deselect();
    }
    if (this.selectedPaymentStatus.length === this.paymentStatusList.length) {
      this.allSelectedPaymentStatus.select();
    }
    this.changePaymentStatusFilter();
  }

  resetPaymentStatusSelection() {
    this.allSelectedPaymentStatus.select();
    this.selectedPaymentStatus = [...this.paymentStatusList.map(item => item.value), 0];
    this.changePaymentStatusFilter();
  }

  changePlanFilter() {
    this.getAllAccounts(1);
  }

  toggleAllTypePlan() {
    if (this.allSelectedPlan.selected) {
      this.selectedPlans = [...this.plansList.map(item => item.value), 0];
    } else {
      this.selectedPlans = [];
    }
    this.changePlanFilter();
  }

  toggleAllInternal() {
    if (this.allSelectedInterval.selected) {
      this.selectedInterval = [...this.interval.map(item => item.value), 0];
    } else {
      this.selectedInterval = [];
    }
    this.changePlanFilter();
  }

  intervalTouchPerOne() {
    if (this.allSelectedInterval.selected) {
      this.allSelectedInterval.deselect();
    }
    if (this.selectedInterval.length === this.interval.length) {
      this.allSelectedInterval.select();
    }
    this.changePlanFilter();
  }

  planTouchPerOne() {
    if (this.allSelectedPlan.selected) {
      this.allSelectedPlan.deselect();
    }
    if (this.selectedPlans.length === this.plansList.length) {
      this.allSelectedPlan.select();
    }
    this.changePlanFilter();
  }

  resetPlanSelection() {
    this.allSelectedPlan.select();
    this.selectedPlans = [...this.plansList.map(item => item.value), 0];
    this.changePlanFilter();
  }

  changeStatusFilter() {
    this.getAllAccounts(1);
  }

  toggleAllTypeStatus() {
    if (this.allSelectedStatus.selected) {
      this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    } else {
      this.selectedStatus = [];
    }
    this.changeStatusFilter();
  }

  statusTouchPerOne() {
    if (this.allSelectedStatus.selected) {
      this.allSelectedStatus.deselect();
    }
    if (this.selectedStatus.length === this.statusList.length) {
      this.allSelectedStatus.select();
    }
    this.changeStatusFilter();
  }

  resetStatusSelection() {
    this.allSelectedStatus.select();
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.changeStatusFilter();
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllAccounts(this.pageNo);
    }
  }

  columnChange(event: MatSelectChange) {
    this.selectedColumns = event.value;
    this.getAllAccounts(this.currentPage);
  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(elem => column.id === elem.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllAccounts(1);
  }

  // #endregion

  getAllAccounts(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      currentPage: pageNo,
      selectedColumns: this.selectedColumns,
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedPlans: this.selectedPlans,
      selectedInterval: this.selectedInterval,
      selectedStatus: this.selectedStatus,
      selectedPaymentStatus: this.selectedPaymentStatus,
      selectedSort: this.selectedSort,
      selectedTrialExpirationRange: this.selectedDateRange,
      trialExpirationFilter: this.trialExpirationFilter
    };
    this.backButtonService.setFilters(this.cookieKey, requestParams);
    this.accountService.getAllAccounts(requestParams).then((accountsListResponse: any) => {
      this.logger.info('INFO: -> AllAccountsComponent -> this.accountService.getAllAccounts -> accountsListResponse', accountsListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Account List refreshed');
      this.accounts = accountsListResponse.accounts;
      if (accountsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        if (this.itemsPerPage === -1) {
          this.toShowing = accountsListResponse.totalCount;
        } else {
          this.toShowing = ((pageNo + 1) * this.itemsPerPage < accountsListResponse.totalCount ?
            (pageNo + 1) * this.itemsPerPage :
            accountsListResponse.totalCount);
        }
      }
      this.totalCount = accountsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> AllAccountsComponent -> this.accountService.getAllAccounts -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  exportAction(option) {
    this.ngxService.start();
    const fileName = 'all_accounts';
    const requestParams = {
      selectedColumns: this.selectedColumns,
      searchText: this.searchString,
      selectedPlans: this.selectedPlans,
      selectedInterval: this.selectedInterval,
      selectedStatus: this.selectedStatus,
      selectedPaymentStatus: this.selectedPaymentStatus,
      selectedSort: this.selectedSort,
      selectedTrialExpirationRange: this.selectedDateRange,
      trialExpirationFilter: this.trialExpirationFilter
    };
    this.accountService.getAllAccountsForExport(requestParams).then((accountsListResponse: any) => {
      const accountsList = accountsListResponse.accounts;
      const exportData = [];
      accountsList.forEach(item => {
        const tempJson = {};
        this.selectedColumns.forEach(column => {
          if (typeof item[column.key] === 'object') {
            tempJson[column.label] = item[column.key][column.key1] &&
            item[column.key][column.key1] !== 'Not Available' ? item[column.key][column.key1] : '';
          } else {
            tempJson[column.label] = item[column.key] && item[column.key] !== 'Not Available' ? item[column.key] : '';
          }
        });
        exportData.push(tempJson);
      });
      if (option === 'excel') {
        this.exportService.exportAsExcelFile(exportData, fileName);
      } else if (option === 'csv') {
        this.exportService.exportToCsvFile(exportData, fileName);
      } else if (option === 'doc') {
        this.exportService.exportToDocFile(exportData, fileName, this.pageHeading, this.companyName);
      }
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.error('ERROR: -> AllAccountsComponent -> this.accountService.getAllAccountsForExport -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error?.error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }

  refreshData() {
    this.getAllAccounts(this.currentPage);
  }

  containsOnly(array1, array2) {
    if (array1.length > 0 && array2.length > 0) {
      return !array2.some(elem => !array1.includes(elem));
    } else {
      return false;
    }
  }

  datesUpdated(event) {
    if (event && event.startDate && event.endDate) {
      this.selectedDateRange.start = dayjs(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRange.end = dayjs(event.endDate).format('MM-DD-YYYY');
      this.getAllAccounts(1);
    }
  }

  goBack() {
    window.history.back();
  }

  setDefaultFilters() {
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedPlans = [...this.plansList.map(item => item.value), 0];
    this.selectedInterval = [...this.interval.map(item => item.value), 0];
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.selectedPaymentStatus = [...this.paymentStatusList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 1, key: 'accountNumber', label: 'Account No', sort: 1, hide: 0},
      {id: 2, key: 'subscriptionOwnerEmail', label: 'Subscription Owner', sort: 0, hide: 0},
      {id: 3, key: 'companyName', label: 'Company Name', sort: 0, hide: 0},
      {id: 4, key: 'status', label: 'Status', sort: 0, hide: 0},
      {id: 5, key: 'planName', label: 'Plan Name', sort: 0, hide: 0},
      {id: 6, key: 'franchisee', key1: 'name', label: 'Franchisee', sort: 0, hide: 0},
      {id: 7, key: 'closingDate', label: 'Closing Date', sort: 0, hide: 0},
      {id: 8, key: 'paymentStatus', key1: 'status', label: 'Payment Status', sort: 0, hide: 0},
      {id: 9, key: 'createdDate', label: 'Account Creation', sort: 0, hide: 0},
      {id: 10, key: 'resellerId', label: 'Reseller Id', sort: 0, hide: 0}
    ];
    this.selectedSort = {key: 'Account No', value: 1};
  }

  setAppliedFilters(filtersValue) {
    console.log('DEBUG: -> AllAccountsComponent -> setAppliedFilters -> filtersValue', filtersValue);
    this.totalCount = 0;
    this.itemsPerPage = filtersValue?.itemsPerPage;
    this.pageNo = filtersValue?.currentPage;
    this.searchString = filtersValue?.searchText;
    this.selectedPlans = filtersValue?.selectedPlans;
    this.selectedInterval = filtersValue?.selectedInterval;
    this.selectedStatus = filtersValue?.selectedStatus;
    this.selectedPaymentStatus = filtersValue?.selectedPaymentStatus;
    this.selectedColumns = filtersValue?.selectedColumns;
    this.selectedSort = filtersValue?.selectedSort;
  }

  resetFilter() {
    this.backButtonService.removeFilters('cf_all_account');
    this.setDefaultFilters();
    this.getAllAccounts(1);
  }
}
