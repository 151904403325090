import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/services/authentication.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ResellerService } from '@app/services/reseller.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-reseller',
  templateUrl: './add-reseller.component.html',
  styleUrls: ['./add-reseller.component.css']
})
export class AddResellerComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;
  companyName: string;
  code: string;
  resellerFormGroup: UntypedFormGroup;
  resellerDetails: any;
  urlReg = /^https:\/\/+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  @ViewChild('stepper') stepper: MatStepper;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private resellerService: ResellerService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger,
    private ngxService: NgxUiLoaderService,
    private authenticationService: AuthenticationService
  ) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && !currentUser.isAccessOfAddEditReseller) {
      this.router.navigate(['/partner/reseller/all']);
    }
    this.code = String(moment().unix());
    this.pageHeading = 'Add Reseller';
    this.resellerFormGroup = this.formBuilder.group({
      id  : '',
      name: ['', Validators.required],
      code: [this.code],
      widgetId: ['', [Validators.required]],
      marketingUrl: ['', Validators.pattern(this.urlReg)],
      showInAsc: [false],
      resellerId: ['', Validators.pattern(/^[a-zA-Z0-9]+$/)],
      templateConfig: this.formBuilder.group({
        logo: this.formBuilder.group({
          url: [''],
          link: [''],
          visible: [false]
        }),
        header: this.formBuilder.group({
          content: ['', Validators.maxLength(140)],
          visible: [false]
        }),
        paragraph: this.formBuilder.group({
          content: [''],
          visible: [false]
        }),
        googleAnalyticsScript: this.formBuilder.group({
          enabled: [false],
          measurementId: ['']
        }),
        facebookPixelScript: this.formBuilder.group({
          enabled: [false],
          pixelId: ['']
        }),
        hotjarScript: this.formBuilder.group({
          enabled: [false],
          siteId: [''],
          version: ['']
        }),
        offers: this.formBuilder.group({
          content: [''],
          visible: [false]
        })
      }),
      singedDocumentURL: ['']
    });
    this.route.data
      .pipe(
        filter((data) => !!data),
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
          this.resellerDetails = data?.resellerDetails;
          this.code = data?.resellerDetails?.code ? data.resellerDetails.code : this.code;
          this.pageHeading = data?.pageHeading;
      });
  }

  ngOnInit(): void {
    this.route.parent.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    if (this.resellerDetails?._id) {
      this.resellerFormGroup.patchValue({
        id  : this.resellerDetails._id,
        name: this.resellerDetails?.name,
        code: this.resellerDetails?.code,
        widgetId:  this.resellerDetails?.widgetId,
        marketingUrl: this.resellerDetails?.marketingUrl,
        showInAsc: this.resellerDetails?.showInAsc,
        resellerId: this.resellerDetails?.resellerId,
        templateConfig: {
        logo: {
          url: this.resellerDetails?.templateConfig?.logo?.url,
          link: this.resellerDetails?.templateConfig?.logo?.link,
          visible: this.resellerDetails?.templateConfig?.logo?.visible
        },
        googleAnalyticsScript: {
          enabled: this.resellerDetails?.templateConfig?.googleAnalyticsScript?.enabled,
          measurementId: this.resellerDetails?.templateConfig?.googleAnalyticsScript?.measurementId
        },
        facebookPixelScript: {
          enabled: this.resellerDetails?.templateConfig?.facebookPixelScript?.enabled,
          pixelId: this.resellerDetails?.templateConfig?.facebookPixelScript?.pixelId,
        },
        hotjarScript: {
          enabled: this.resellerDetails?.templateConfig?.hotjarScript?.enabled,
          siteId: this.resellerDetails?.templateConfig?.hotjarScript?.siteId,
          version: this.resellerDetails?.templateConfig?.hotjarScript?.version,
        },
        header: {
          content: this.resellerDetails?.templateConfig?.header?.content,
          visible: this.resellerDetails?.templateConfig?.header?.visible,
        },
        paragraph: {
          content: this.resellerDetails?.templateConfig?.paragraph?.content,
          visible: this.resellerDetails?.templateConfig?.paragraph?.visible
        },
        offers: {
          content: this.resellerDetails?.templateConfig?.offers?.content,
          visible: this.resellerDetails?.templateConfig?.offers?.visible
        },
      },
      singedDocumentURL: this.resellerDetails?.singedDocumentURL
      });
    }

    this.resellerFormGroup.get('resellerId').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        this.resellerFormGroup.get('marketingUrl').setValue(environment.PARTNER_URL + '/partners/' + value);
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  get resellerForm(){
    return this.resellerFormGroup.controls;
  }

  saveReseller() {
    if (this.resellerFormGroup.valid) {
      if (this.pageHeading === 'Add Reseller') {
        this.resellerService.addReseller(this.resellerFormGroup.value).then((resellerDetails: any) => {
          this.logger.info('INFO: -> AddResellerComponent -> this.resellerService.addReseller -> resellerDetails', resellerDetails);
          this.commonUtility.displaySuccessToast(this.pageHeading, resellerDetails?.message);
          this.router.navigate(['/partner/reseller/all'], { queryParams: { search: resellerDetails?.widgetId }});
        }).catch(error => {
          this.logger.error('ERROR: -> AddResellerComponent -> this.resellerService.addReseller -> error', error);
          this.errorHandling(error);
        });
      } else {
        const requestPara = Object.assign({}, this.resellerFormGroup.value);
        requestPara.id =  this.resellerDetails._id;
        this.resellerService.editReseller(requestPara).then((resellerDetails: any) => {
          this.logger.info('INFO: -> AddResellerComponent -> this.resellerService.editReseller -> resellerDetails', resellerDetails);
          this.commonUtility.displaySuccessToast(this.pageHeading, resellerDetails?.message);
          this.router.navigate(['/partner/reseller/all'], { queryParams: { search: resellerDetails?.widgetId }});
        }).catch(error => {
          this.logger.error('ERROR: -> AddResellerComponent -> this.resellerService.editReseller -> error', error);
          this.errorHandling(error);
        });
      }
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

  errorHandling(error) {
    if (error.status === 400) {
      const validationError = error.error.errors;
      let errorToast = '';
      Object.keys(validationError).forEach((index) => {
        const formControl = this.resellerFormGroup.get(
          validationError[index].param
        );
        if (formControl) {
          formControl.setErrors({
            serverError: validationError[index].message,
          });
          formControl.markAsTouched();
          errorToast += `<strong>${validationError[index].param}:</strong> ${validationError[index].message} <br>`;
        }
      });
      this.commonUtility.displayErrorToast(this.pageHeading, errorToast);
      if (errorToast.includes('widgetId')) {
        this.stepper.selectedIndex = 0;
      }
      if (!errorToast.includes('widgetId') && errorToast.includes('resellerId')) {
        this.stepper.selectedIndex = 1;
      }
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, error?.error);
    }
  }

  onUpdateLogo(event) {
    this.ngxService.start();
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      const profileImage = event.target.files[0];
      formData.append('image', profileImage);
      formData.append('resellerId', this.code);
      this.resellerService.updateLogo(formData).then((response: any) => {
        if (response) {
          this.commonUtility.displaySuccessToast('Upload Logo', response.message);
          this.resellerFormGroup.get('templateConfig.logo.url').setValue(response.url);
          this.ngxService.stop();
          this.ngxService.stopBackground();
        } else {
          this.commonUtility.displayErrorToast('Upload Logo',
            'Please upload a valid image file. Supported image formats are JPG, JPEG and PNG.');
          this.ngxService.stop();
          this.ngxService.stopBackground();
        }
      }).catch(error => {
        this.commonUtility.displayErrorToastWithTechDetails('Upload Logo', error.error);
        this.ngxService.stop();
        this.ngxService.stopBackground();
      });
    }
  }

  onUpdateSignedDoc(event) {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      const profileImage = event.target.files[0];
      formData.append('image', profileImage);
      formData.append('resellerId', this.code);
      this.resellerService.updateSignedContract(formData).then((response: any) => {
        if (response) {
          this.commonUtility.displaySuccessToast('Upload Signed Contract', response.message);
          this.resellerFormGroup.get('singedDocumentURL').setValue(response.url);
          this.ngxService.stop();
          this.ngxService.stopBackground();
        } else {
          this.commonUtility.displayErrorToast('Upload Signed Contract',
            'Please upload a valid image file. Supported image formats are JPG, JPEG and PNG.');
          this.ngxService.stop();
          this.ngxService.stopBackground();
        }
      }).catch(error => {
        this.commonUtility.displayErrorToastWithTechDetails('Upload Signed Contract', error.error);
        this.ngxService.stop();
        this.ngxService.stopBackground();
      });
    }
  }

}
